import React from 'react';
import CssVariables from 'styles/scss/variables.scss';

export function FormStyle(props) {
    return  {
        menu: (provided, state) => ({
            ...provided,
            padding: "0 !important",
            cursor: 'pointer !important',
            zIndex: '4 !important',
          }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
          color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
          fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
          fontSize: CssVariables.defaultFontSize + " !important",
          cursor: 'pointer !important',
          
        }),
        control: (provided, state) => ({
          ...provided,
          
          padding:"0 !important",
          margin:"0 !important",
          boxSizing: 'border-box !important',
          boxShadow: 'none !important',
          boxShadow: state.isFocused?`0 0 1px 1px ${CssVariables.defaultGreenColor} !important` : 'none !important',
          borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
          minHeight:"30px !important",
          borderRadius: "5px !important",
        }),
        placeholder:(provided, state) => ({
            ...provided,
            textTransform: "none !important",
        }),
        indicatorSeparator:(provided, state) => ({
            ...provided,
            width:"0 !important",
        }),
        indicatorsContainer:(provided, state) => ({
            ...provided,
            padding:"0 !important",
            alignSelf:"auto !important",
            //minHeight:"25px !important",
            maxHeight:"25px !important",

        }),
        input:(provided, state) => ({
            ...provided,
            paddingTop:"0 !important",
            paddingBottom:"0 !important",
            margin: '0 !important',
            color: CssVariables.defaultTextColor + ' !important',
        }),
        multiValue:(provided, state) => ({
            ...provided,
            backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
        }),
        multiValueLabel:(provided, state) => ({
            ...provided,
            color: CssVariables.defaultTextColorDark + ' !important',
            '&:hover': {
              backgroundColor: CssVariables.defaultThemeColor + ' !important',
              color: '#ffffff !important',
              cursor: 'pointer !important'
            }
        }),
        multiValueRemove:(provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
            '&:hover': {
              backgroundColor: CssVariables.defaultThemeColor + ' !important',
              color: '#ffffff !important',
              cursor: 'pointer !important'
            }
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms !important';
      
          return { 
              ...provided, 
              
              transition,
              //Pagination ma katincha single value, padding diyena vane
              color: "hsl(0,0%,20%)",
              padding:"1px"
            };
        }
      }
}


//  For Select Inputs that needs seperate Z-index in Sticky Tables.
export function FormStyle1(props) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          cursor: 'pointer !important',
          zIndex: '2 !important',
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSize + " !important",
        cursor: 'pointer !important',
        
      }),
      control: (provided, state) => ({
        ...provided,
        
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: state.isFocused?`0 0 1px 1px ${CssVariables.defaultGreenColor} !important` : 'none !important',
        borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
        minHeight:"30px !important",
        borderRadius: "5px !important",
        
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",

      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            opacity, 
            transition,
            //Pagination ma katincha single value, padding diyena vane
            padding:"1px"
          };
      }
    }
}


//  For Select Inputs that need only bottom border [Example: POS Order Page]
export function FormStyle2(props) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          cursor: 'pointer !important',
          zIndex: '4 !important'
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSize + " !important",
        cursor: 'pointer !important',
        
      }),
      control: (provided, state) => ({
        ...provided,
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: 'none !important',
        minHeight:"30px !important",
        border: "0 !important",
        borderBottom: "1px solid " + CssVariables.defaultBorderColor + " !important",
        borderRadius: "0px !important",
        '&:hover': {
          borderBottom: "1px solid " + CssVariables.defaultThemeColor + " !important"
        }
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",
      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            opacity, 
            transition,
            //Pagination ma katincha single value, padding diyena vane
            padding:"1px"
          };
      }
    }
}


//  For Select Input with multiselect in Pagination table column
export function FormStyleForPagination(props) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          cursor: 'pointer !important',
          zIndex: '4 !important',
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSize + " !important",
        cursor: 'pointer !important',
        
      }),
      control: (provided, state) => ({
        ...provided,
        
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: 'none !important',
        boxShadow: state.isFocused?`0 0 1px 1px ${CssVariables.defaultGreenColor} !important` : 'none !important',
        borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
        minHeight:"30px !important",
        minWidth:"300px !important",
        borderRadius: "5px !important",
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",

      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            
            transition,
            //Pagination ma katincha single value, padding diyena vane
            color: "hsl(0,0%,20%)",
            padding:"1px"
          };
      }
    }
}


//  For Select Inputs that is extra small [Example: FO Reservation Page]
export function XSmallFormStyle(zIndex = 2, forMinSpace = false) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          cursor: 'pointer !important',
          zIndex: `${zIndex} !important`,
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? ((state.isDisabled ? CssVariables.defaultBackgroundColorRed : CssVariables.defaultThemeColorBackground) + ' !important') : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSizeForMinSpace + " !important",
        cursor: 'pointer !important',
        
      }),
      control: (provided, state) => ({
        ...provided,
        
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: state.isFocused?`0 0 1px 1px ${CssVariables.defaultGreenColor} !important` : 'none !important',
        borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
        minHeight:"28px !important",
        borderRadius: "5px !important",
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",
      }),
      clearIndicator:(provided, state) => ({
        ...provided,
        padding: forMinSpace ? "0 !important" : "2px !important",
      }),
      dropdownIndicator:(provided, state) => ({
        ...provided,
        padding:"2px !important",
      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            
            transition,
            //Pagination ma katincha single value, padding diyena vane
            color: "hsl(0,0%,20%)",
            padding:"1px",
            fontSize: forMinSpace ? (CssVariables.defaultFontSizeForMinSpace + ' !important') : "inherit",
          };
      }
    }
}


//  For Select Inputs that is used in POS Touch Order Menu
export function FormStyle3(props) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          cursor: 'pointer !important',
          zIndex: '4 !important',
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSize + " !important",
        cursor: 'pointer !important',
        
      }),
      control: (provided, state) => ({
        ...provided,
        
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: 'none !important',
        boxShadow: 'none !important',
        borderColor: 'transparent !important',//added this line for focus color. Need to change it.
        minHeight:"30px !important",
        borderRadius: "5px !important",
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",

      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            
            transition,
            //Pagination ma katincha single value, padding diyena vane
            color: "hsl(0,0%,20%)",
            padding:"1px"
          };
      }
    }
}


//  For Large Select Inputs that are used in POS Touch Order General Information
export function LargeFormStyle(props) {
  return  {
    menu: (provided, state) => ({
        ...provided,
        padding: "0px !important",
        cursor: 'pointer !important',
        zIndex: '2 !important',
      }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? CssVariables.defaultThemeColorBackground + ' !important' : '',
      color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
      fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
      cursor: 'pointer !important',
      paddingTop: '15px !important',
      paddingBottom: '15px !important',
    }),
    control: (provided, state) => ({
      ...provided,
      
      padding:"5px !important",
      margin:"0 !important",
      boxSizing: 'border-box !important',
      boxShadow: state.isFocused?`0 0 1px 1px ${CssVariables.defaultGreenColor} !important` : 'none !important',
      borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
      minHeight:"28px !important",
      borderRadius: "5px !important",
    }),
    placeholder:(provided, state) => ({
        ...provided,
        textTransform: "none !important",
    }),
    indicatorSeparator:(provided, state) => ({
        ...provided,
        width:"0 !important",
    }),
    indicatorsContainer:(provided, state) => ({
        ...provided,
        padding:"0 !important",
        alignSelf:"auto !important",
        //minHeight:"25px !important",
        maxHeight:"25px !important",

    }),
    input:(provided, state) => ({
        ...provided,
        paddingTop:"0 !important",
        paddingBottom:"0 !important",
        margin: '0 !important',
        color: CssVariables.defaultTextColor + ' !important',
    }),
    multiValue:(provided, state) => ({
        ...provided,
        backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
    }),
    multiValueLabel:(provided, state) => ({
        ...provided,
        color: CssVariables.defaultTextColorDark + ' !important',
        '&:hover': {
          backgroundColor: CssVariables.defaultThemeColor + ' !important',
          color: '#ffffff !important',
          cursor: 'pointer !important'
        }
    }),
    multiValueRemove:(provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
        '&:hover': {
          backgroundColor: CssVariables.defaultThemeColor + ' !important',
          color: '#ffffff !important',
          cursor: 'pointer !important'
        }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms !important';
  
      return { 
          ...provided, 
          
          transition,
          //Pagination ma katincha single value, padding diyena vane
          color: "hsl(0,0%,20%)",
          padding:"1px"
        };
    }
  }
}


//  For Select Inputs that need only bottom border and special style [Example: FO Guest Info (GI) header]
export function MenuStyle(props) {
  return  {
      menu: (provided, state) => ({
        ...provided,
        padding: "5px !important",
        cursor: 'pointer !important',
        zIndex: '4 !important'
      }),
      option: (provided, state) => ({
        ...provided,
        padding: "12px 15px !important",
        backgroundColor: state.isFocused ? CssVariables.defaultBackgroundColorText + ' !important' : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSize + " !important",
        borderRadius: "5px !important",
        cursor: 'pointer !important',
      }),
      control: (provided, state) => ({
        ...provided,
        background: "transparent !important",
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: 'none !important',
        minHeight:"30px !important",
        border: "0 !important",
        borderBottom: "1px solid " + (state.isFocused ? CssVariables.defaultThemeColor : CssVariables.defaultBorderColor) + " !important",
        borderRadius: "0px !important",
        '&:hover': {
          borderBottom: "1px solid " + CssVariables.defaultThemeColor + " !important"
        }
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          alignSelf:"auto !important",
          //minHeight:"25px !important",
          maxHeight:"25px !important",
      }),
      input:(provided, state) => ({
          ...provided,
          paddingTop:"0 !important",
          paddingBottom:"0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            opacity, 
            transition,
            //Pagination ma katincha single value, padding diyena vane
            padding:"1px"
          };
      }
    }
}


//  For Select Inputs in inline filter [Example: POS Pre Order Inline Filter]
export function InlineTransparentSelect(zIndex = 2, forMinSpace = false) {
  return  {
      menu: (provided, state) => ({
          ...provided,
          padding: "0 !important",
          margin: "0 !important",
          cursor: 'pointer !important',
          zIndex: `${zIndex} !important`,
        }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? ((state.isDisabled ? CssVariables.defaultBackgroundColorRed : CssVariables.defaultThemeColorBackground) + ' !important') : '',
        color: state.isSelected ? CssVariables.defaultThemeColor + ' !important' : CssVariables.defaultTextColor + ' !important',
        fontWeight: state.isSelected ? 'bold !important' : 'normal !important',
        fontSize: CssVariables.defaultFontSizeForMinSpace + " !important",
        cursor: 'pointer !important',
      }),
      control: (provided, state) => ({
        ...provided,
        background: "transparent !important",
        padding:"0 !important",
        margin:"0 !important",
        boxSizing: 'border-box !important',
        boxShadow: 'none !important',
        borderColor: state.isFocused? CssVariables.defaultGreenColor + ' !important':CssVariables.defaultBorderColor + ' !important',//added this line for focus color. Need to change it.
        minHeight:"28px !important",
        borderRadius: "5px !important",
        border: "0 !important",
        outline: "none !important",
      }),
      placeholder:(provided, state) => ({
          ...provided,
          textTransform: "none !important",
      }),
      indicatorSeparator:(provided, state) => ({
          ...provided,
          width:"0 !important",
      }),
      indicatorsContainer:(provided, state) => ({
          ...provided,
          padding:"0 !important",
          margin:"0 !important",
          alignSelf:"auto !important",
          maxHeight:"25px !important",
      }),
      clearIndicator:(provided, state) => ({
        ...provided,
        padding: forMinSpace ? "0 !important" : "2px !important",
      }),
      dropdownIndicator:(provided, state) => ({
        ...provided,
        padding:"2px !important",
      }),
      input:(provided, state) => ({
          ...provided,
          border: "0 !important",
          padding: "0 !important",
          margin: '0 !important',
          color: CssVariables.defaultTextColor + ' !important',
      }),
      multiValue:(provided, state) => ({
          ...provided,
          backgroundColor: CssVariables.defaultThemeColorBackground + ' !important',
      }),
      multiValueLabel:(provided, state) => ({
          ...provided,
          color: CssVariables.defaultTextColorDark + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      multiValueRemove:(provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? CssVariables.defaultBorderColor + ' !important' : CssVariables.defaultThemeColorBackground + ' !important',
          '&:hover': {
            backgroundColor: CssVariables.defaultThemeColor + ' !important',
            color: '#ffffff !important',
            cursor: 'pointer !important'
          }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms !important';
    
        return { 
            ...provided, 
            
            transition,
            //Pagination ma katincha single value, padding diyena vane
            color: "hsl(0,0%,20%)",
            padding:"1px",
            fontSize: forMinSpace ? (CssVariables.defaultFontSizeForMinSpace + ' !important') : "inherit",
          };
      }
    }
}


export const Theme=theme => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: CssVariables.defaultThemeColorBackground,
      danger: CssVariables.defaultThemeColorBackground,
      primary: CssVariables.defaultTextColorLight,
      neutral80:CssVariables.defaultThemeColorBackground,
      neutral10:'white'
    },
  })

